import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../services/menu.service/menu.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  
  constructor(private router: Router, protected menuService: MenuService) { 
  }

  ngOnInit() {
    if (this.menuService.redirectHomePage)
      this.router.navigateByUrl('register');
  }  
}
