import { Component, Inject, Injector } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { BsModalService } from "ngx-bootstrap/modal";
import { FormsService } from "projects/urp/src/app/services/forms.service/forms.service";
import { HelperService } from "projects/urp/src/app/services/helper.service/helper.service";
import { HttpService } from "projects/urp/src/app/services/http-service/http.service";
import { LocalStorageService } from "projects/urp/src/app/services/storage.service/local-storage.service";
import { ToastrServiceExt } from "projects/urp/src/app/services/toastr.service/toastr.service";
import { BaseFormComponent } from "../../../shared/base-form/base-form.component";
import { IConfig } from "projects/urp/src/app/models/config.interface";
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { TranslateService } from "@ngx-translate/core";
import { formEnum } from "projects/urp/src/app/enumerators/Forms.enum";
import { MenuService } from "projects/urp/src/app/services/menu.service/menu.service";

@Component({
  selector: 'app-user-form',
  templateUrl: '../../../shared/base-form/base-form.component.html',
  styleUrls: ['../../../shared/base-form/base-form.component.scss']
})
export class UserFormComponent extends BaseFormComponent {

  adminEdit = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected formsService: FormsService,
    protected location: Location,
    protected toastrService: ToastrServiceExt,
    protected httpService: HttpService,
    protected helper: HelperService,
    protected readonly keycloak: KeycloakService,
    protected modal: BsModalService,
    protected storage: LocalStorageService,
    protected injector: Injector,
    protected translateService: TranslateService,
    protected menuService: MenuService,
    @Inject(APP_CONFIG) protected readonly environment: IConfig) {
    super(route, router, formsService, location, toastrService, httpService, helper, keycloak, modal, storage, translateService, injector, environment);
  }

  ngOnInit(): void {
    super.ngOnInit();
    //TODO needs security in backend, but how ??
    if (this.route.snapshot.data['adminEdit']) {
      this.adminEdit = this.route.snapshot.data['adminEdit'];
    }
  }

  protected override save(formData: any): void {
    formData.data.FormId = this.formId;
    formData.data.FormCode = this.formCode;
    const data = Object.assign({}, formData.data, this.params);
    delete data.RolesReadonly; // TODO jei galima padaryt kad formio nesiustu sitos reiksmes ji tik perziurai    
    delete data.AdminEdit;

    if (this.formCode === formEnum.internalUserForm) {
      this.httpService.post(`${this.environment.api.umUser}/SaveInternal`, data).subscribe(res => {
        this.successSave(res, this.formCode);
      }, (e) => {
        this.error(e);
      });
    }
    else if (this.formCode === formEnum.externalUserForm) {
      this.httpService.post(`${this.environment.api.umUser}`, data).subscribe(res => {
        this.successSave(res, this.formCode);
      }, (e) => {
        this.error(e);
      });
    }
    this.clickSuccess.emit(formData.instance);
  }

  protected override async loadData(formCode, id: string) {
    try {
      const res = await this.httpService.get(`${this.environment.api.umUser}/${id}`).toPromise();
      const data = res.Data ? JSON.parse(res.Data) : res;
      this.afterLoad(data);
      this.setSubmission(data, id);
    } catch (e) {
      this.error(e);
    }
  }

  protected override afterLoad(response: any): void {
    response.RolesReadonly = response.Roles;
    response.AdminEdit = this.adminEdit;
  }

  protected override async setFormDataAndLoad(res: any, id: any): Promise<void> {
    this.form.components = res.components;
    this.form.display = res.display;
    this.formCode = res.code;
    this.formTitle = res.title;
    this.formVersionNo = res.versionId;
    this.formId = res._id;
    if (id) {
      await this.loadData(res.code, id);
    } else {
      this.submitted = true;
      this.submission = { data: { AdminEdit: this.adminEdit} };
    }
    this.refreshForm.emit({
      form: this.form
    });
  }

  protected override customEvent(event: any): void {
    switch (event.type) {
      case 'ResetOTP':
        this.httpService.get(`${this.environment.api.umUser}/resetOTP/${String(this.id)}`).subscribe(res => {
          this.toastrService.successTranslatable('pages.common.form.successSaveMessage');
        }, (e) => {
          this.error(e);
        });
        break;
      case 'ResetPassword':
      this.openConfirmationModal('confirmationMessages.resetPasswordTitle', 'confirmationMessages.resetPasswordMessage', false, false, true);

        this.modalRef.content.clickEvent.subscribe(userConfirmed => {
          if (userConfirmed) {
            this.httpService.get(`${this.environment.api.umUser}/resetPassword/${String(this.id)}`).subscribe(res => {
              this.toastrService.successTranslatable('pages.common.form.successSaveMessage');
            }, (e) => {
              this.error(e);
            });
          }
        });
        break;
      default:
        super.customEvent(event);
        break;
    }
  }
}