import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { KeycloakService } from 'keycloak-angular';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { routesEnum } from '../../../enumerators/routesEnum';
import { IConfig } from '../../../models/config.interface';
import { FormsService } from '../../../services/forms.service/forms.service';
import { HttpService } from '../../../services/http-service/http.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { RegisterServiceService } from '../../../services/register-service.service/register-service.service';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { DefaultParams } from '../../../models/filter.model'
import { ActionInitiatorType } from '../../../enumerators/classifier.enum';

export class RegisterServiceDTO {
    Id: Guid;
    Name: string;
    ProcessDefinitionKey: string;
    ValidFrom: Date;
    ValidTo: Date;
    RegisterId: Guid;
    IsFavorite: Boolean;
    ThumbnailEntityId: Guid;
    Description: string;
    ThumbnailRowOrder: number;
}

export class RegisterServiceTypeDTO {
    Name: string;
    Services: Array<RegisterServiceDTO>;
}

export class RegisterDTO {
    Id: Guid;
    Name: string;
    RegisterServiceTypes: Array<RegisterServiceTypeDTO>;
}

@Component({
    selector: 'app-register-services-list',
    templateUrl: './register-services-list.component.html'
})

export class RegisterServicesListComponent implements OnInit {
    loading = false;
    routesEnum = routesEnum;
    registers: Array<RegisterDTO>;
    registersToShow : Array<RegisterDTO>;
    now = new Date();

    registerId:Guid;
    serviceNameLike:string;
    inboxImage = '/assets/images/inbox.jpg';
    searchData = new DefaultParams('NaturalKey asc');

    favoriteServicesEnabled = true; 
    registerServicesWithThumbnails: RegisterServiceDTO[] = [];
    apiUrl: any;
    constructor(protected toastrService: ToastrServiceExt,
        protected modal: BsModalService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected readonly keycloak: KeycloakService,
        protected apiService: HttpService,
        protected formsService: FormsService,
        protected registerServiceService: RegisterServiceService,
        protected httpService: HttpService,
        protected location: Location,
        @Inject(APP_CONFIG) private readonly environment: IConfig) {
    }

    ngOnInit() {
        this.loading = true;
        this.inboxImage = '/assets/images/' + this.environment.env.projectCode + '-inbox.jpg';
        this.apiUrl = this.environment.api.appRegister + '/searchRegistersWithServices';
        this.searchData.PageSize = 100;
        this.searchData['isValid'] = true;
        this.searchData['ActionInitiatorTypeIds'] = Guid.parse(ActionInitiatorType.User);
        if(this.activatedRoute.parent 
            && this.activatedRoute.parent.parent
            && this.activatedRoute.parent.parent.parent
            && this.activatedRoute.parent.parent.parent.parent)
        this.activatedRoute.parent.parent.parent.parent.params.subscribe((params) => {
            this.searchData['Ids'] = params.registerId; 
        });

        this.loadData();             
    }
    loadData() {
        this.apiService.getList(this.apiUrl, this.searchData).subscribe((response) => {
            this.registers = response;
            this.onFilterLocal();
            this.loading = false;
          }, (err) => {
            this.loading = false;
            this.toastrService.serverError(err);
          }
        )
    }
       

    openDetails(serviceId: Guid, registerId: Guid) {
        this.router.navigate(['../' + routesEnum.registerServicesList.url + '/'+ routesEnum.registerServiceDetails.url], 
            { 
                relativeTo: this.activatedRoute,
                queryParams: { 
                    RegisterId: registerId, 
                    RegisterServiceId: serviceId
                } 
            });
    }

    startProcess(serviceId: Guid, processDefinitionKey: string, registerId: Guid,formCode: string, formId: string): void {
        this.registerServiceService.startProcess(
            serviceId, 
            processDefinitionKey, 
            registerId, 
            formCode, 
            formId,
            this.activatedRoute 
        );
    }

    loadRegisterServicesWithThumbnails(){
        this.registerServicesWithThumbnails = [];
        this.registersToShow.forEach((register) => {
            register.RegisterServiceTypes.filter((registerServiceTypes) => {
                registerServiceTypes.Services.forEach((service) => {
                    if (service.IsFavorite === true && service.ThumbnailEntityId) {
                        if(this.registerServicesWithThumbnails.length < 5) { 
                            this.registerServicesWithThumbnails.push(service); 
                        }
                    }
                })
            })
        });

        this.registerServicesWithThumbnails.sort((a, b) => {
            if(!a.ThumbnailRowOrder) return 1;
            if(!b.ThumbnailRowOrder) return -1;

            return a.ThumbnailRowOrder - b.ThumbnailRowOrder;
        });
    }

    onFilterLocal(){
        this.registersToShow = JSON.parse(JSON.stringify(this.registers));
        const name = this.searchData['Name'];
        if(name)
        {
            this.registersToShow = this.registersToShow.filter(function(registers) {
                registers.RegisterServiceTypes = registers.RegisterServiceTypes.filter(function(serviceType){
                    serviceType.Services = serviceType.Services.filter(function(service){
                        return service.Name.toLowerCase().indexOf(name.toLowerCase()) !== -1;
                    });
                    return serviceType.Services.length > 0;
                });
                return registers.RegisterServiceTypes.length > 0;
            });
        }
        this.loadRegisterServicesWithThumbnails();
    }    
}
