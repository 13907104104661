<div *ngIf="loading" class="container-fluid">
    <app-loader type="list"></app-loader>
</div>
<div *ngIf="!loading">
    <div id="main" class="cp-ps-0 cp-pe-0 cp-pt-0 page--inbox">
        <div class="cp-page-header" [ngStyle]="{'background-image':'url(' + inboxImage + ')'}">
            <div class="wrap wrap--full d-flex flex-column flex-sm-row justify-content-between align-items-lg-center">
                <h1 class="text-white cp-font-weight-700 cp-mb-0">{{'pages.register.service.list.title' | translate}}</h1>
                <label class="cp-page-header__search">
                    <input id="Name" class="cp-input cp-input--search" type="text" matInput (keyup)="onFilterLocal()" [(ngModel)]="searchData.Name" placeholder="{{'pages.services.searchPlaceholder' | translate}}" />
                </label>
            </div>
        </div>
    </div>
    <div class="mt-4 mx-auto main-wrapper cp-px-md-10">
        <div *ngIf="favoriteServicesEnabled">
            <div class="cp-mb-20 cp-tc-md-auto" *ngIf="registerServicesWithThumbnails.length > 0">
                <h4 class="cp-mb-20 text-start">{{'pages.registerServicesList.popularServices' | translate }}</h4>
                <div class="d-inline-block" *ngFor="let service of registerServicesWithThumbnails">
                    <app-thumbnail [buttonText]="'pages.services.startButton' | translate"
                                [register]="service"
                                ($onClick)="startProcess(service.Id, service.ProcessDefinitionKey, service.RegisterId, service.FormCode, service.FormId)"
                                [tooltipText]="service.Description | translate"></app-thumbnail> <!--tool tip text is a placeholder, need to know where it will come from-->
                </div>
            </div>
        </div>
        <div *ngFor="let register of registersToShow">
            <h4 class="cp-mb-20">{{register.Name | translate}}</h4>
            <div *ngFor="let registerServiceType of register.RegisterServiceTypes">
                <div class="cp-cards cp-mb-20">
                    <div class="cp-card cp-bg-primary--light">
                        <div class="cp-card__head">
                            <h6 class="cp-mb-0 text-uppercase cp-text-xsmall cp-font-weight-600">{{registerServiceType.Name | translate}}</h6>
                        </div>
                    </div>
                    <div class="cp-card" *ngFor="let service of registerServiceType.Services">
                        <div class="cp-card__head d-flex align-items-center justify-content-between">
                            <div>
                                <p class="cp-mb-2">{{service.Name  | translate}}</p>
                                <p class="cp-text-xsmall cp-mb-0 cp-text-base">{{service.Description | translate}}</p>
                            </div>

                            <div class="d-flex gap-10">
                                <a [routerLink]="" class="cp-btn cp-btn--primary cp-btn--outline cp-font-weight-600" (click)="openDetails(service.Id, register.Id)" *ngIf="service.HasDetailsPage">
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36842 9.5C4.36842 12.3341 6.66591 14.6316 9.5 14.6316C12.3341 14.6316 14.6316 12.3341 14.6316 9.5C14.6316 6.66591 12.3341 4.36842 9.5 4.36842C6.66591 4.36842 4.36842 6.66591 4.36842 9.5ZM9.5 3C5.91015 3 3 5.91015 3 9.5C3 13.0899 5.91015 16 9.5 16C13.0899 16 16 13.0899 16 9.5C16 5.91015 13.0899 3 9.5 3Z" fill="currentColor"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60312 6.95208C9.3085 6.90154 9.0055 6.95691 8.74778 7.10837C8.49006 7.25983 8.29427 7.49761 8.19507 7.7796C8.06967 8.13607 7.67905 8.32339 7.32258 8.19799C6.96611 8.07259 6.7788 7.68197 6.90419 7.3255C7.11063 6.73866 7.5181 6.24381 8.05442 5.92861C8.59075 5.6134 9.22133 5.49818 9.83447 5.60335C10.4476 5.70852 11.0037 6.02729 11.4044 6.50321C11.8049 6.97903 12.0242 7.58122 12.0233 8.20318C12.023 9.21605 11.2724 9.88187 10.7437 10.2343C10.4584 10.4245 10.1781 10.5642 9.9717 10.6559C9.86755 10.7022 9.77981 10.7373 9.7165 10.7615C9.68478 10.7735 9.65904 10.7829 9.64025 10.7896L9.61734 10.7976L9.60999 10.8001L9.60738 10.801L9.60635 10.8014L9.6059 10.8015C9.6057 10.8016 9.6055 10.8016 9.38913 10.1526L9.6055 10.8016C9.24701 10.9211 8.85953 10.7274 8.74003 10.3689C8.62061 10.0107 8.81403 9.62343 9.17209 9.50368L9.17159 9.50384L9.17109 9.50401C9.17099 9.50405 9.17133 9.50393 9.17211 9.50366L9.18181 9.50026C9.19159 9.49679 9.20774 9.49093 9.22934 9.4827C9.27268 9.46619 9.33727 9.4404 9.41593 9.40544C9.5752 9.33465 9.78233 9.2306 9.9846 9.09575C10.4308 8.7983 10.6549 8.48933 10.6549 8.20255L10.6549 8.20153C10.6554 7.9026 10.55 7.61316 10.3575 7.38448C10.165 7.15579 9.89775 7.00261 9.60312 6.95208ZM12.0233 8.20318C12.0233 8.20331 12.0233 8.20344 12.0233 8.20357L11.3392 8.20255H12.0233C12.0233 8.20276 12.0233 8.20297 12.0233 8.20318ZM8.75692 12.7526C8.75692 12.3747 9.06325 12.0683 9.44113 12.0683H9.44763C9.82551 12.0683 10.1318 12.3747 10.1318 12.7526C10.1318 13.1304 9.82551 13.4368 9.44763 13.4368H9.44113C9.06325 13.4368 8.75692 13.1304 8.75692 12.7526Z" fill="currentColor"/>
                                    </svg>
                                    {{'pages.services.detailsButton' | translate }}
                                </a>
                                <a [routerLink]="" class="cp-btn cp-btn--primary cp-btn--wider" (click)="startProcess(service.Id, service.ProcessDefinitionKey, register.Id, service.FormCode, service.FormId)">
                                    {{'pages.services.startButton' | translate }}
                                </a>
                            </div>
                        </div>
                    </div>

                       
                </div>
            </div>
        </div>
    </div>
</div>
