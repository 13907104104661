import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routesEnum } from '../../../enumerators/routesEnum';
import { KeycloakService } from 'keycloak-angular';
import { APP_CONFIG } from '../../../utility/tokens';
import { IConfig } from '../../../models/config.interface';
import { HttpService } from '../../../services/http-service/http.service';
import { combineLatest, Subscription } from 'rxjs';
import { ContextService } from '../../../services/context.service/context.service';
import { MenuService } from '../../../services/menu.service/menu.service';

@Component({
  selector: 'app-my-office',
  templateUrl: './my-office.component.html',
  styleUrls: ['./my-office.component.scss']
})
export class MyOfficeComponent implements OnInit, OnDestroy {

  routesEnum = routesEnum;
  registers: any;
  userInfo: any;
  loading = true;
  representative: any;
  representatives: any;
  representativesSubscription: Subscription;
  menuItemsSubscription: Subscription;
  myOfficeMenuItems: any;

  constructor(
    public keycloak: KeycloakService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected httpService: HttpService,
    protected menuService: MenuService,
    private context: ContextService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
  }

  ngOnInit(): void {
    this.getUserData();
    this.httpService.get(`${this.environment.api.appRegister}/search`).subscribe(res => {
      this.registers = res;
      this.loading = false;
    });
    this.representativesSubscription = combineLatest([this.context.representations, this.context.representationId])
      .subscribe(result => {
        if (result[0]) {
          this.representatives = result[0];
          this.representative = this.representatives.Data.filter(x => x.Id == result[1])[0];
        }
      });

    this.menuItemsSubscription = this.menuService.myOfficeMenuItems.subscribe(result => {
      this.myOfficeMenuItems = result;
    });
  }

  ngOnDestroy(): void {
    if (this.representativesSubscription) {
      this.representativesSubscription.unsubscribe();
    }
    if (this.menuItemsSubscription) {
      this.menuItemsSubscription.unsubscribe();
    }
  }

  selectPaymetRegister(item): void {
    alert('Method not implemented');
  }
  getUserData() {
    void this.keycloak.getKeycloakInstance().loadUserInfo().then(user => {
      this.userInfo = user;
      this.loading = false;
    });
  }

  isReprsentativeVisible(): boolean {
    if (this.myOfficeMenuItems && this.myOfficeMenuItems.filter(q => q.path == routesEnum.myOfficeRepresentativesList.url).length > 0) {
      return true;
    }
    return false;
  }

  isRegisterVisible(): boolean {
    if (this.myOfficeMenuItems && this.myOfficeMenuItems.filter(q => q.path == routesEnum.myOfficeRegistersList.url).length > 0) {
      return true;
    }
    return false;
  }
}
