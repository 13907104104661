import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BaseStorageService } from './base-storage.service';

@Injectable({
    providedIn: 'root'
  })
export class SessionStorageService extends BaseStorageService {

    public KEY_REPRESENTATIONS = "REPRESENTATIONS";
    public KEY_REPRESENTATION_ID = "REPRESENTATION_ID";
    public KEY_REPRESENTATION_ROLES = "REPRESENTATION_ROLES";
    public KEY_FIRST_TIME_PAGE_LOAD = "KEY_FIRST_TIME_PAGE_LOAD";

    constructor(@Inject(SESSION_STORAGE) protected storage: StorageService) { 
        super(storage);
    }

}