import { Component, Inject, ViewChild, ElementRef, Input, SimpleChanges, EventEmitter } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum' 
import { DefaultParams } from '../../../../models/filter.model'
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { HttpService } from '../../../../services/http-service/http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { Location } from '@angular/common';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { Subscription } from 'rxjs';
import { FormioComponent } from '@formio/angular';
import { LocalStorageService } from 'projects/urp/src/app/services/storage.service/local-storage.service';
import { FormsService } from 'projects/urp/src/app/services/forms.service/forms.service';
import { TranslateService } from '@ngx-translate/core';
import { ObjectHelpers } from 'projects/core/src/lib/shared/helpers/object-helpers';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-account-type-list',
  templateUrl: './account-type-list.component.html'
})
export class AccountTypeListComponent 
extends BaseListComponent {

  form: any;
  listingFormJson : any;
  isFilterClosed = true;
  formOptions: any;
  formSubmission: any = {};
  query: any = {};

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  // actions = ['edit', 'view', 'delete', 'list'];
  actions = [ 'view', 'edit', 'delete'];
  searchData = new DefaultParams('Name asc');
  defaultSearchData = new DefaultParams('Name asc');
  
  listUrl = routesEnum.accountTypeList.url;
  formEnum = formEnum.accountTypeForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('Name', 'pages.accountType.list.data.name'),
    new TableColumn('AccountTypeId', 'pages.accountType.list.data.accountType', 'guid', 'ACCOUNTTYPES'),
    new TableColumn(null, null, 'action')
  ];

  @Input() filterHeader: string;

  language = new EventEmitter<string>();
    addTranslations = new EventEmitter<any>();
    refreshForm = new EventEmitter();
    languageSubscription: Subscription;
    @ViewChild('formio') formioComponent: FormioComponent;
    cachedLanguages = {};
    
    listFormId : string;

    formId = null; // set in defineParameters

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected location: Location,
              protected httpService: HttpService,
              protected storage: LocalStorageService,
              protected formsService: FormsService,
              protected translateService: TranslateService,
              protected helperService: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpService); 
    this.apiUrl = this.environment.api.accountTypes;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.AccountTypeConfiguration);
    this.form = { display: 'form', components: []};
    this.getOptions();
  }

  protected override loadClassifiers(): void {
    this.httpService.get(`${this.environment.api.classifier}/SelectSearch?ClassifierDomainNaturalIds=ACCOUNT-TYPE`).subscribe(res => {
        this.classifierData.ACCOUNTTYPES = res;
        this.classifiersLoading = false;
      }, (err) => {
        this.toastrService.serverError(err);
      });
  }

  ngOnInit(): void {
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      if (this.formioComponent)
        this.addLanguage(this.getCurrentLanguage());
    });

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.query = queryParams;
    });
    this.loadData();
  }
  
  private onToList(item: unknown): void {
    const url = `${this.listUrl}/${routesEnum.registerFunctionalityList.url}`;
    void this.router.navigate([url], {queryParams: {}});
  }

  protected loadData() {

    this.formsService.getFormById(this.environment.formIds.accountTypeListFormId).subscribe(res => {
        const response : any = res;
        this.form.components = response.components;
        this.searchData = Object.assign({}, this.defaultSearchData);
        this.refreshForm.emit({
            form: this.form
          });
        super.ngOnInit();

        this.loadList();
    });
}

getOptions(): void {
    const lang = this.getCurrentLanguage();
    this.formOptions = {
      template: 'bootstrap5',
      disableAlerts: true,
      noAlerts: true,
      language: lang.toLowerCase(),
      i18n: {
        [lang.toLowerCase()]: this.storage.retrieve(`translations_${lang}`)
      }
    }
    this.cachedLanguages[lang.toLowerCase()] = true;
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  ngOnDestroy() {
    if (this.languageSubscription){
        this.languageSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  addLanguage(lang: string) : void {
    if (!this.cachedLanguages[lang.toLowerCase()]) {
      this.formioComponent.formio.addLanguage(lang.toLowerCase(), this.storage.retrieve(`translations_${lang}`));
      this.cachedLanguages[lang.toLowerCase()] = true;
    }
    this.language.emit(lang.toLowerCase());
  }

  public formioCustomEvent(event: any): void {
    const mergedObject = {
      ...this.searchData,
      ...event.data
    };
    switch (event.type) {
      case 'filter':
        this.searchData = mergedObject;
        this.onFilter();
        break;
      case 'clear':
        this.clearFilter();
        break;
      default:
        alert(`Event ${event.type} is not handled for this form yet.`);
    }
  }

  public formioChange(event: any): void {

      if(JSON.stringify(event.metadata) == JSON.stringify({})){
        for (const key of this.query.keys ?? []) {
          const values = this.query.getAll(key);
            if (values.length === 1) {
              this.searchData[key] = values.shift();
            }
            else {
              this.searchData[key] = values;
            }    
        }
    
        this.refreshForm.emit({
          submission: {
            data: {
              filter: ObjectHelpers.unflatten(this.searchData)
            }
          }
        });
      }
  
      if(event.data?.filter){
        const mergedObject = {
          ...this.searchData,
          ...ObjectHelpers.flatten(event.data.filter)
        };
        this.searchData = mergedObject;
      }
    }

  render() : void {
    const lang = this.getCurrentLanguage();
    this.language.emit(lang.toLowerCase());
  }

  protected override clearFilter(): void {
    this.searchData = Object.assign({}, this.defaultSearchData);
    this.refreshForm.emit({
      submission: {
        data: {
          filter: ObjectHelpers.unflatten(this.searchData)
        }
      }
    });
    this.loadList();
  }

  private onImport(): void {
    this.fileInput.nativeElement.click();
  }

  private onExport(): void {
    const tempSearchData = { ...{}, ...this.searchData };
    delete tempSearchData.SortBy;
    delete tempSearchData.PageSize;
    delete tempSearchData.Page;

    const param = this.httpService.getQueryParams(tempSearchData);
    const url = `${this.environment.api.accountTypes}/export?${param}`;

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.get(url, httpOptions).subscribe(res => {
      const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "accountTypeConfigurations.json";
      link.click();
      link.remove();

      this.loading = false;
    },
      (err) => {
        this.toastrService.serverError(err);
      });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.type === 'application/json') {
        this.uploadFile(file);
      } else {
        this.toastrService.serverError('Only JSON files are allowed.');
      }
    }
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.post(`${this.environment.api.accountTypes}/import`, formData, httpOptions).subscribe(
      (res) => {
        const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = "accountTypeConfigurationImportResult.json";
        link.click();
        link.remove();

        this.loading = false;
        this.fileInput.nativeElement.value = "";
      },
      (err) => {
        this.toastrService.serverError(err);
        this.fileInput.nativeElement.value = "";
      }
    );
  }  
}