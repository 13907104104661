import { Component, Inject, Input, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HelperService } from '../../../../services/helper.service/helper.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { MenuService } from 'projects/urp/src/app/services/menu.service/menu.service';
import { Observable } from 'rxjs/Rx';
import { SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';
import { BaseMenuComponent } from '../../../shared/base-menu/base-menu';
import { RootMenuType } from 'projects/urp/src/app/enumerators/RootMenu.enum';
import { SessionStorageService } from 'projects/urp/src/app/services/storage.service/session-storage.service';


@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
})
export class SidebarMenuComponent extends BaseMenuComponent implements OnInit {
  userName: any;
  activeUrl: string;
  showSidebar = true;
  showMenu = true;
  sideBarSecondData: any;
  main: any;
  children: any;
  constructor(
    private keycloak: KeycloakService,
    private router: ActivatedRoute,
    private _router: Router,
    private helper: HelperService,
    protected menuService: MenuService,
    protected toastrService: ToastrServiceExt,
    protected httpService: HttpService,
    private storageService: SessionStorageService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(menuService);
  }

  ngOnInit(): void {
    void this.keycloak.loadUserProfile().then(user => {
      this.userName = user.username;
      this.menuService.refreshMenuEvent.subscribe(() => {
        this.refreshMenu();
      });
    });
    this.menuService.selectedSideBarItem$.subscribe((value) => {
      if (!value) {
        this.removeExpanded();
      } else if (value.menuItemId) {
        this.setActiveItem(value);
      }
    });
    this.menuService.showMenuEvent.subscribe((value) => {
      this.refreshMenu();
      this.showMenu = value;
    });
  }
  refreshMenu(): void {
    this.menuStructure = [];
    Observable.forkJoin([
      this.httpService.get(`${this.environment.api.menuItems}`),
      this.httpService.get(`${this.environment.api.appRegister}/registersForMenu?isValid=true`)
    ])
      .subscribe(data => {
        this.menuStructure = data[0]
          .Data
          .filter(q => q.RootMenu == RootMenuType.Sidemenu)
          .map(item => ({
            label: item.Label,
            path: item.Path,
            iconClass: item.IconClass + ' c-icon c-icon-20',
            toggle: false,
            dirty: false,
            parentMenuItemId: item.ParentMenuItemId,
            order: item.Order ? item.Order : 0,
            menuItemId: item.MenuItemId,
            queryParams: {},
            activeMenu: false,
            expanded: false
          }));

        this.menuService.myOfficeMenuItems.next(data[0]
          .Data
          .filter(q => q.RootMenu == RootMenuType.Myoffice)
          .map(item => ({
            label: item.Label,
            path: item.Path,
            iconClass: item.IconClass + ' c-icon c-icon-20',
            toggle: false,
            dirty: false,
            parentMenuItemId: item.ParentMenuItemId,
            order: item.Order ? item.Order : 0,
            menuItemId: item.MenuItemId,
            queryParams: {},
            activeMenu: false,
            expanded: false
          })));

        const serv = this.menuStructure.find(m => m.label == 'sidemenu.services');
        let servOrder = serv?.order ?? 0;
        data[1].sort(function (a, b) {
          return a.RowOrder - b.RowOrder;
        });
        data[1].forEach(register => {
          if (register.FunctionalityConfigurations && register.FunctionalityConfigurations.length > 0) {
            this.menuStructure.push({
              label: register.Name,
              iconClass: register.IconClass + ' c-icon c-icon-grey c-icon-20',
              path: routesEnum.register.url + '/' + register.Id + '/functionality',
              queryParams: { RegisterId: register.Id },
              toggle: false,
              dirty: false,
              menuItemId: register.Id,
              order: ++servOrder,
              activeMenu: false,
              expanded: false
            });
            register.FunctionalityConfigurations.sort(function (a, b) {
              return a.OrderSequence - b.OrderSequence;
            });
            register.FunctionalityConfigurations.forEach(functionality => {
              if (functionality) {
                this.menuStructure.push({
                  label: functionality.Name,
                  parentMenuItemId: register.Id,
                  queryParams: { RegisterId: register.Id },
                  path: this.formatFunctionalityUrl(functionality),
                  toggle: false,
                  dirty: false,
                  order: ++servOrder,
                  activeMenu: false,
                  menuItemId: functionality.Id,
                  expanded: false
                });
              }
            });
          }
        });
        this.menuStructure.sort(function (a, b) {
          return a.order - b.order;
        });
        if (serv) {
          this.menuService.redirectHomePage = true;
          this.closeNav(serv);
          if (!this.storageService.exists(this.storageService.KEY_FIRST_TIME_PAGE_LOAD)) {
            this.storageService.store(this.storageService.KEY_FIRST_TIME_PAGE_LOAD, false);
            if (!this._router.url.startsWith('/printoutQRCodeVerification')) {
              this._router.navigateByUrl('register');
            }
          } 
        }
      }, (err) => {
        this.toastrService.serverError(err);
      });

  }

  toggleMenu(item, mobile): void {
    //this.removeActive();
    this.removeExpanded();
    this.sideBarSecondData = this.menuStructure.filter(m => m.parentMenuItemId == item.menuItemId || m.menuItemId == item.menuItemId);
    this.main = this.sideBarSecondData.find(m => !m.parentMenuItemId);
    this.children = this.sideBarSecondData.filter(m => m.parentMenuItemId);
    this.menuService.setSideBarSecondData(this.sideBarSecondData);
    if (!mobile)
      this.minSideMenu();
    //item.activeMenu = true;
    item.expanded = true;
  }

  isActiveUrl(menuItem): boolean {
    var route = menuItem.path;
    var queryParams = menuItem.queryParams;
    if (menuItem.activeMenu)
      return true;
    else
      return false;
  }
  hasChild(item) {
    if (item.parentMenuItemId)
      return true;
    else {
      if (this.menuStructure.filter(m => m.parentMenuItemId == item.menuItemId).length > 0)
        return true;
      else
        return false;
    }
  }
  getItemChildren(item) {
    return this.menuStructure.filter(m => m.parentMenuItemId == item.menuItemId);
  }

  shouldExpandAutomatically(parent): boolean {
    if (parent.isDirty) {
      return false;
    }
    const children = this.menuStructure.filter(m => m.parentMenuItemId == parent.menuItemId);

    if (!children || children.length === 0) {
      return false;
    }

    for (const child of children) {

      if (this.isActiveUrl(child.path)) {
        parent.toggle = !parent.toggle;
        parent.isDirty = true;
        return true;
      }
    }
    return false;
  }
  formatFunctionalityUrl(registerFunctionality) {
    let url = '';
    if (registerFunctionality.RegisterFunctionalityType === 0)//service
    {
      url = 'register/' + registerFunctionality.RegisterId + '/functionality/' + registerFunctionality.Id + '/ro/' + routesEnum.registerServicesList.url;
    }
    else if (registerFunctionality.RegisterFunctionalityType === 1)//List
    {
      if (registerFunctionality.ListModule == SubSystem.APP) {
        url = 'register/' + registerFunctionality.RegisterId + '/functionality/' + registerFunctionality.Id + '/dl/' + registerFunctionality.ListForm
          + '/' + registerFunctionality.ListModule + '/' + routesEnum.registerApplicationsList.url;
      }
      else if (registerFunctionality.ListModule == SubSystem.REG) {
        url = 'register/' + registerFunctionality.RegisterId + '/functionality/' + registerFunctionality.Id + '/dl/' + registerFunctionality.ListForm
          + '/' + registerFunctionality.ListModule + '/' + routesEnum.registerRegistersList.url;
      }
    }
    else if (registerFunctionality.RegisterFunctionalityType === 2)//Task
    {
      url = 'register/' + registerFunctionality.RegisterId + '/functionality/' + registerFunctionality.Id + '/ro/' + routesEnum.registerTaskList.url;
    }
    return url;
  }
  closeSubMenu() {
    this.menuStructure.forEach(m => {
      m.toggle = false;
    });
  }
  
  setIconClass(menuItem) {
    if (menuItem.activeMenu) {
      menuItem.iconClass = menuItem.iconClass.replace('c-icon-grey', 'c-icon-primary');
    } else {
      menuItem.iconClass = menuItem.iconClass.replace('c-icon-primary', 'c-icon-grey');
    }
    return menuItem.iconClass;
  }
  closeSecondMenu() {
    this.sideBarSecondData = null;
  }
  setActiveItem(data) {
    this.removeActive();
    this.menuStructure.forEach(m => {
      if ((m.menuItemId == data.parentMenuItemId) || (m.menuItemId == data.menuItemId))
        m.activeMenu = true;
    });
  }
}
