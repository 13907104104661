import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum'
import { DefaultParams } from '../../../../models/filter.model'
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { HttpService } from '../../../../services/http-service/http.service';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { Location } from '@angular/common';
import { ToastrServiceExt } from '../../../../services/toastr.service/toastr.service';
import { HelperService } from '../../../../services/helper.service/helper.service';
import { IConfig } from '../../../../models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { ObjectHelpers } from 'projects/core/src/lib/shared/helpers/object-helpers';
import { FormioComponent } from '@formio/angular';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from 'projects/urp/src/app/services/forms.service/forms.service';
import { LocalStorageService } from 'projects/urp/src/app/services/storage.service/local-storage.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent extends BaseListComponent implements OnInit {
  searchData = new DefaultParams('Name asc');
  defaultSearchData = new DefaultParams('Name asc');

  form: any;
  formOptions: any;
  cachedLanguages = {};
  @ViewChild('formio') formioComponent: FormioComponent;
  query: any = {};
  languageSubscription: Subscription;

  apiUrl = this.environment.api.umAccounts;
  listUrl = routesEnum.accountList.url;
  formEnum = formEnum.accountForm;
  actions: string[] = ['view', 'edit'];
  
  tableColumns = [
    new TableColumn('AccountTypeId', 'pages.account.list.data.accountType', 'guid', "accountTypes"),
    new TableColumn('AccountName', 'pages.account.list.data.accountName'),    
    new TableColumn('IdentifierTypeId', 'pages.account.list.data.identifierType', 'guid', "IdentifierTypes"),
    new TableColumn('Identifier', 'pages.account.list.data.identifier'),
    new TableColumn('CreatedOn', 'pages.account.list.data.createdOn', 'datetime'),
    new TableColumn(null, null, 'action')
  ];

  refreshForm = new EventEmitter();
  language = new EventEmitter<string>();

  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected readonly keycloak: KeycloakService,
    protected httpService: HttpService,
    protected location: Location,
    protected activatedroute: ActivatedRoute,
    protected helperService: HelperService,
    protected formsService: FormsService,
    protected translateService: TranslateService,
    protected storage: LocalStorageService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedroute, keycloak, location, httpService);
    this.form = { display: 'form', components: []};
    this.getOptions();
  }
  protected override loadClassifiers(): void {
    this.httpService.get(`${this.environment.api.classifier}/SelectSearch?ClassifierDomainNaturalIds=ACCOUNT-TYPE`).subscribe(res => {
            this.classifierData.accountTypes = res;
            this.classifiersLoading = false;
          }, (err) => {
            this.toastrService.serverError(err);
          });
    this.httpService.get(`${this.environment.api.classifier}/SelectSearch?ClassifierDomainNaturalIds=IDENTIFICATION_TYPE`).subscribe(res => {
      this.classifierData.IdentifierTypes = res;
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
    this.httpService.get(`${this.environment.api.classifier}/SelectSearch?ClassifierDomainNaturalIds=COUNTRY`).subscribe(res => {
      this.classifierData.IdentifierIssuedBy = res;
      this.loadList();
      this.loadFilterForm();
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
}

protected override clearFilter(): void {
    this.searchData = Object.assign({}, this.defaultSearchData, {  relativeTo: this.activatedRoute});
    this.refreshForm.emit({
        submission: {
          data: {
            filter: ObjectHelpers.unflatten(this.searchData)
          }
        }
      });
    this.loadList();
}
loadFilterForm() {
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
        this.addLanguage(this.getCurrentLanguage());
      });
  
    this.formsService.getFormById(this.environment.formIds.accountListFormId).subscribe(res => {        
        let response : any = res;
        this.form.components = response.components;
        this.searchData = Object.assign({}, this.defaultSearchData);
        this.refreshForm.emit({
            form: this.form
          });
    });
}
getOptions(): void {
const lang = this.getCurrentLanguage();
this.formOptions = {
    template: 'bootstrap5',
    disableAlerts: true,
    noAlerts: true,
    language: lang.toLowerCase(),
    i18n: {
    [lang.toLowerCase()]: this.storage.retrieve(`translations_${lang}`)
    }
}
this.cachedLanguages[lang.toLowerCase()] = true;
}
getCurrentLanguage(): string {
return this.translateService.currentLang;
}
addLanguage(lang: string) : void {
    if (!this.cachedLanguages[lang.toLowerCase()]) {
      this.formioComponent.formio.addLanguage(lang.toLowerCase(), this.storage.retrieve(`translations_${lang}`));
      this.cachedLanguages[lang.toLowerCase()] = true;
    }
    this.language.emit(lang.toLowerCase());
}
public formioCustomEvent(event: any): void {
    const mergedObject = {
      ...this.searchData,
      ...event.data
    };
    switch (event.type) {
      case 'filter':
        this.searchData = mergedObject;
        this.onFilter();
        break;
      case 'clear':
        this.clearFilter();
        break;
      default:
        alert(`Event ${event.type} is not handled for this form yet.`);
    }
}
public formioChange(event: any): void {
    if(JSON.stringify(event.metadata) == JSON.stringify({})){
      for (const key of this.query.keys ?? []) {
        const values = this.query.getAll(key);
          if (values.length === 1) {
            this.searchData[key] = values.shift();
          }
          else {
            this.searchData[key] = values;
          }    
      }
      this.refreshForm.emit({
        submission: {
          data: {
            filter: ObjectHelpers.unflatten(this.searchData)
          }
        }
      });
    }
    if(event.data?.filter){
      const mergedObject = {
        ...this.searchData,
        ...ObjectHelpers.flatten(event.data.filter)
      };
      this.searchData = mergedObject;
    }
  }

  render() : void {
    const lang = this.getCurrentLanguage();
    this.language.emit(lang.toLowerCase());
  }    
}