import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MenuService {
    redirectHomePage = false;
    refreshMenuEvent = new BehaviorSubject<any>(null);
    showMenuEvent = new BehaviorSubject<boolean>(null);
    showSidebarEvent = new BehaviorSubject<any>(true);
    private sideBarItem$ = new BehaviorSubject<any>({});
    selectedSideBarItem$ = this.sideBarItem$.asObservable();
    private sideBarSecondData$ = new BehaviorSubject<any>({});
    selectedSideBarSecondData$ = this.sideBarSecondData$.asObservable();

    myOfficeMenuItems = new BehaviorSubject<any>(null);

    constructor() {}
    setSideBarActive(data) {
      this.sideBarItem$.next(data);
    } 
    setSideBarSecondData(data) {
      this.sideBarSecondData$.next(data);
    }    
}